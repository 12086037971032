import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Label } from '@entur/typography';
import { GridContainer, GridItem } from '@entur/grid';
import PageHeader from '~/components/PageHeader';
import CodePlaygroundImage from '../../../../../.readme-media/code-playground-editor.png';
import TestBenchImage from '../../../../../.readme-media/test-bench-editor.png';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <PageHeader mdxType="PageHeader">
  Har du funnet en bug i designsystemet? Vil du legge til en ny feature eller
  fikse noe i dokumentasjonen? Eller kanskje bare utforske repoet? Da er dette
  guiden for deg.
    </PageHeader>
    <h2 {...{
      "id": "introduksjon"
    }}>{`Introduksjon`}</h2>
    <p><em parentName="p">{`For en mer detaljert beskrivelse, se `}<a parentName="em" {...{
          "href": "https://bitbucket.org/enturas/design-system/src/master/README.md"
        }}>{`README.md`}</a>{` i repoet.`}</em></p>
    <p>{`Monorepoet inneholder alle npm-pakkene som er utgitt av Entur Designsystem, samt `}<a parentName="p" {...{
        "href": "https://design.entur.no"
      }}>{`dokumentasjonssiden`}</a>{` og en kode-lekeplass for lokal testing.`}</p>
    <p>{`Monorepoet er delt inn i `}<inlineCode parentName="p">{`packages`}</inlineCode>{` og `}<inlineCode parentName="p">{`apps`}</inlineCode>{`. `}<inlineCode parentName="p">{`packages`}</inlineCode>{` inneholder alle pakkene vi utgir til npm. `}<inlineCode parentName="p">{`apps`}</inlineCode>{` inneholder tjenester som bruker komponentene og verktøyene under `}<inlineCode parentName="p">{`packages`}</inlineCode>{`, i vårt tilfelle dokumentasjonen og en code-playground for lokal testing.`}</p>
    <h2 {...{
      "id": "krav"
    }}>{`Krav`}</h2>
    <p><em parentName="p">{`MERK: disse instruksjonene er kun testet for MAC OS`}</em></p>
    <p>{`Designsystemet sitt monorepoet trenger følgende verktøy for å kjøre ordentlig:`}</p>
    <ul>
      <li parentName="ul">{`Node.JS 16.17.0`}</li>
      <li parentName="ul">{`yarn 1.22.19`}</li>
    </ul>
    <p>{`Node.js er tilgjengelig på `}<a parentName="p" {...{
        "href": "https://nodejs.org/en/download"
      }}>{`Node's nettside`}</a>{`. Hvis du vil enkelt bytte mellom forskjellige versjoner av Node.js (forskjellige repoer kan bruke forskjellige versjoner) så er `}<a parentName="p" {...{
        "href": "https://github.com/nvm-sh/nvm"
      }}>{`nvm`}</a>{` et godt alternativ.`}</p>
    <p>{`Yarn kan enkelt installeres med f.eks. `}<a parentName="p" {...{
        "href": "https://classic.yarnpkg.com/lang/en/docs/install/#mac-stable"
      }}>{`npm`}</a>{` eller `}<a parentName="p" {...{
        "href": "https://formulae.brew.sh/formula/yarn#default"
      }}>{`brew`}</a>{`.`}</p>
    <h2 {...{
      "id": "førstegangsoppsett"
    }}>{`Førstegangsoppsett`}</h2>
    <h3 {...{
      "id": "kloning-av-repoet-fra-bitbucket"
    }}>{`Kloning av repoet fra BitBucket`}</h3>
    <p>{`Repoet er tilgjengelig på `}<a parentName="p" {...{
        "href": "https://bitbucket.org/enturas/design-system/src"
      }}>{`BitBucket`}</a>{` (Merk at du må være i Entur for å ha tilgang til dette repoet). For å klone det, må du sette opp en ssh-nøkkel på Mac-en din og legge til den offentlige nøkkelen i BitBucket-kontoen din (dvs. autentisere datamaskinen din). Hvis du allerede har gjort dette, kan du hoppe over neste trinn.`}</p>
    <ul>
      <li parentName="ul">{`Følg `}<a parentName="li" {...{
          "href": "https://support.atlassian.com/bitbucket-cloud/docs/set-up-personal-ssh-keys-on-macos/"
        }}>{`BitBuckets veiledning`}</a>{` om hvordan du setter opp SSH-nøkkeltilkoblingen din.`}</li>
    </ul>
    <p>{`Når ssh-nøkkelen din er satt opp, kjør følgende skript:
`}<inlineCode parentName="p">{`git clone git@bitbucket.org:enturas/design-system.git`}</inlineCode></p>
    <p>{`Alternativt kan du klikke på `}<inlineCode parentName="p">{`clone`}</inlineCode>{` -knappen på BitBucket-repo-siden og følge instruksjonene.`}</p>
    <h3 {...{
      "id": "installer-avhengigheter-og-bygg-pakker"
    }}>{`Installer avhengigheter og bygg pakker`}</h3>
    <p>{`Hvis det er første gang du utvikler i repoet, kjør `}<inlineCode parentName="p">{`yarn setup`}</inlineCode>{`. Den vil installere avhengigheter og bygge alle komponenter.`}</p>
    <p>{`Hvis dette fungerer, flott! Hvis ikke, spør en voksen om hjelp …`}</p>
    <h2 {...{
      "id": "bruk--komponentbibliotek"
    }}>{`Bruk – komponentbibliotek`}</h2>
    <h3 {...{
      "id": "utvikling-av-pakker"
    }}>{`Utvikling av pakker`}</h3>
    <p>{`Alle pakkene ligger under `}<inlineCode parentName="p">{`packages/`}</inlineCode>{`. De er publisert under `}<inlineCode parentName="p">{`@entur/[pakkenavn]`}</inlineCode>{` der `}<inlineCode parentName="p">{`[pakkenavn]`}</inlineCode>{` er mappenavnet under `}<inlineCode parentName="p">{`packages/`}</inlineCode>{` (uten firkantede paranteser), f.eks. button. Se en komplett liste på `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/org/entur"
      }}>{`npm`}</a>{`.`}</p>
    <p>{`Start med å opprette en `}<em parentName="p">{`branch`}</em>{` for din `}<em parentName="p">{`bugfix`}</em>{` eller `}<em parentName="p">{`feature`}</em>{`. `}<em parentName="p">{`Branch`}</em>{`-en skal ideelt sett starte med Jira-issue-id-en, f.eks. ETU-38373-branch-navn. Når du fikser bugs eller legger til nye funksjoner i en pakke anbefales det å forhåndsvise og teste komponenten ved hjelp av code-playground. Code-playground bruker `}<inlineCode parentName="p">{`playroom`}</inlineCode>{` (`}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/playroom"
      }}>{`tilgjengelig fra npm`}</a>{`) for å forhåndsvise koden din. Start code-playground med `}<inlineCode parentName="p">{`yarn start:code-playground`}</inlineCode>{` og start `}<em parentName="p">{`watch mode`}</em>{` for pakken med `}<inlineCode parentName="p">{`yarn start:package [pakkenavn]`}</inlineCode>{`. Det siste skriptet er nødvendig for å gjenspeile kodeendringene dine i code-playground. Alternativt kan du bruke skriptet `}<inlineCode parentName="p">{`yarn start:code-playground-for-package [pakkenavn]`}</inlineCode>{` for å starte både kodelekeplassen og `}<em parentName="p">{`watch mode`}</em>{` for `}{`[pakkenavn]`}{`.`}</p>
    <p>{`Testutviklingskoden kan enten skrives i den innebygde editoren i kodelekeplassen eller ved hjelp av `}<inlineCode parentName="p">{`TestBench.tsx`}</inlineCode>{` under `}<inlineCode parentName="p">{`apps/code-playground/src`}</inlineCode>{` (`}<a parentName="p" {...{
        "href": "#editor-images"
      }}>{`se bildet nedenfor`}</a>{`). Hvis du vil bruke `}<em parentName="p">{`state`}</em>{` eller annen mer kompleks logikk i testingen, må du bruke filen `}<inlineCode parentName="p">{`TestBench.tsx`}</inlineCode>{`.`}</p>
    <GridContainer spacing="large" mdxType="GridContainer">
  <GridItem small={12} style={{
        display: 'flex',
        flexDirection: 'column'
      }} mdxType="GridItem">
    <img src={CodePlaygroundImage} width="100%" alt="Innebygd kodeeditor i kodelekeplassen" />
    <Label as="p" mdxType="Label">Innebygd kodeeditor i kodelekeplassen</Label>
  </GridItem>
  <GridItem small={12} style={{
        display: 'flex',
        flexDirection: 'column'
      }} mdxType="GridItem">
    <img src={TestBenchImage} width="100%" alt="TestBench-fil i VSCode-editor" />
    <Label as="p" mdxType="Label">Innebygd kodeeditor i kodelekeplassen</Label>
  </GridItem>
    </GridContainer>
    <h3 {...{
      "id": "skrive-tester-for-koden-din"
    }}>{`Skrive tester for koden din`}</h3>
    <p>{`Repoet inneholder støtte for Jest tester. Hvis det lar seg gjøre, anbefales det å skrive tester for fiksen eller funksjonaliteten du har lagt til. Tester kan legges til ved å lage en `}<inlineCode parentName="p">{`komponentnavn.test.jsx`}</inlineCode>{`-fil og skrive Jest-kode i den. Se andre Jest-test-filer for eksempel på hvordan du kan skrive tester.`}</p>
    <h3 {...{
      "id": "commit-av-endringene-dine"
    }}>{`Commit av endringene dine`}</h3>
    <p>{`Siden commitene som er laget brukes både som en endringslogg og for å spore om vi gir ut en patch-, minor- eller majorversjon (`}<a parentName="p" {...{
        "href": "https://www.conventionalcommits.org/en/v1.0.0/"
      }}>{`se conventional-commits`}</a>{`), må vi forsikre oss om at commitene er skrevet riktig. For å gjøre dette bruker vi `}<a parentName="p" {...{
        "href": "https://github.com/commitizen/cz-cli"
      }}>{`Commitizen`}</a>{`, som lar oss bygge en riktig formatert commit-melding og lint den for feil. Som en ekstra forsikring blir alle nye commit-meldinger også lintet ved `}<inlineCode parentName="p">{`git push`}</inlineCode>{` for å forsikre oss om at vi ikke glemmer riktig formatering (`}<em parentName="p">{`MERK: kan overstyrres ved å bruke HUSKY=0`}</em>{`).`}</p>
    <p>{`Når funksjonen eller feilrettingen din er ferdig, legg til endringene dine og commit dem med `}<inlineCode parentName="p">{`yarn gc:format`}</inlineCode>{`. Dette aktiverer Commitizen og forsikrer at commitene er skrevet med riktig formatering.`}</p>
    <p>{`Hvordan fylle ut commit-meldingen:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`type`}</strong>{` skal velges basert på (`}<a parentName="li" {...{
          "href": "https://www.conventionalcommits.org/en/v1.0.0/"
        }}>{`conventional-commits`}</a>{`)`}</li>
      <li parentName="ol"><strong parentName="li">{`scope`}</strong>{` skal inneholde komponenten eller pakken som påvirkes i små bokstaver med mellomrom, f.eks. square button`}</li>
      <li parentName="ol"><strong parentName="li">{`short description`}</strong>{` er en setning om hvilken endring dette vil legge til, alltid i påførende form, f.eks. add new button type duodenary`}</li>
      <li parentName="ol"><strong parentName="li">{`long description`}</strong>{` er for når mer informasjon kanskje er nødvendig for å forklare fiks/funksjonen`}</li>
      <li parentName="ol"><strong parentName="li">{`breaking changes`}</strong>{` skal inneholde informasjon om alle breaking changes gjort i denne committen, dvs. endringer som kan gjøre eksisterende bruk ikke lenger fungerer`}</li>
      <li parentName="ol"><strong parentName="li">{`issues closed`}</strong>{` kan hoppes over`}</li>
      <li parentName="ol"><strong parentName="li">{`affected packages`}</strong>{` skal automatisk være riktig. Bare bekreft med enter`}</li>
    </ol>
    <p>{`Ingen feil? Flott! Ellers prøv igjen …`}</p>
    <h3 {...{
      "id": "tldr"
    }}>{`Tl;dr`}</h3>
    <ol>
      <li parentName="ol">{`Du vil legge til en funksjon i `}<inlineCode parentName="li">{`[pakkenavn]`}</inlineCode>{`, f.eks. button`}</li>
      <li parentName="ol">{`Opprett en gren for fiks/funksjonen`}</li>
      <li parentName="ol">{`Kjør `}<inlineCode parentName="li">{`yarn start:code-playground`}</inlineCode>{` og `}<inlineCode parentName="li">{`yarn start:package [pakkenavn]`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Gjør endringene dine i `}<inlineCode parentName="li">{`packages/[pakkenavn]`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Se endringene live på `}<inlineCode parentName="li">{`localhost:90000`}</inlineCode></li>
      <li parentName="ol">{`Legg til endringene dine og commite med `}<inlineCode parentName="li">{`yarn gc:format`}</inlineCode></li>
      <li parentName="ol">{`Push endringene dine til BitBucket`}</li>
    </ol>
    <h2 {...{
      "id": "bruk--dokumentasjonsside"
    }}>{`Bruk – dokumentasjonsside`}</h2>
    <p>{`Innholdet i de forskjellige dokumentasjonssidene finner du i `}<inlineCode parentName="p">{`content`}</inlineCode>{`-mappen under `}<inlineCode parentName="p">{`apps/documentation`}</inlineCode>{`. Hver side er lagt i mapper basert på hvilken del
av siden den tilhører, og hver av filene er i `}<inlineCode parentName="p">{`MDX`}</inlineCode>{`-formatet. Les mer om MDX på `}<a parentName="p" {...{
        "href": "https://mdxjs.com/"
      }}>{`MDX sine sider`}</a>{`.`}</p>
    <p>{`For komponent-dokumentasjonen er det prøvd å standardisere utseendet og strukturen på hver side. Kopier gjerne en eksisterende fil om du skal legge til en ny side.`}</p>
    <h3 {...{
      "id": "frontmatter--metadata-for-dokumentasjonssiden"
    }}>{`Frontmatter – metadata for dokumentasjonssiden`}</h3>
    <p>{`Vi bruker noe som heter `}<inlineCode parentName="p">{`frontmatter`}</inlineCode>{` på toppen av hver dokumentasjonsside-fil (se kodeeksemplet som følger). Dette er metainformasjon for dokumentasjonssiden og kan være
tittelen på siden, hvilken NPM-pakke denne komponenten tilhører, hvilken rekkefølge den burde dukke opp iog i hvilken meny osv. Frontmatter er formatert i `}<inlineCode parentName="p">{`yaml`}</inlineCode>{`.`}</p>
    <p>{`Følgende valg er tilgjengelige i frontmatter-YAML-en:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`title: Tittel på siden
route: /url/til/siden
order: 1
parent: Hovedkategori (Komponenter, Kom i gang osv)
menu: Hvilken undermeny (kun relevant for komponenter-sider)
npmPackage: navnet på npm-pakken (kun relevant for komponenter-sider)
tags: søkbare, ord (kun relevant for komponenter-siden)
`}</code></pre>
    <p>{`Det finnes et par andre også (`}<inlineCode parentName="p">{`index`}</inlineCode>{`, `}<inlineCode parentName="p">{`frontpage`}</inlineCode>{` etc), men de trenger du mest sannsynligvis aldri å bry deg om. Om i tvil, ta en titt på kildekoden.`}</p>
    <h3 {...{
      "id": "komponenter-og-utils-for-dokumentasjonssiden"
    }}>{`Komponenter og utils for dokumentasjonssiden`}</h3>
    <p>{`Trenger du å endre noe på selve dokumentasjonssiden eller lage en intern-komponent for å dokumentere noe, så skal kode for dette ligge under `}<inlineCode parentName="p">{`apps/documentation/src`}</inlineCode>{`.
Dette kan være kode for live-fremvisning av Designsystem-komponenter, utils, sidestruktur (header og footer) o.l.`}</p>
    <p>{`Mappen inneholder tre mapper:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`gatsby-theme-docz`}</inlineCode>{` inneholder toppnivå-komponenter og kode som gjør kall til eksterne tjenester.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`utils`}</inlineCode>{` inneholder utilities som brukes på kryss og tvers av siden`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`components`}</inlineCode>{` inneholder en rekke gjenbrukbare interne dokumentasjonsside-komponenter. Disse brukes både i selve side-strukturen og som hjelpekomponenter i dokumentering, eg. Playground.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      